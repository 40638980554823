<template>
  <div class="container">
    <div class="main">
      <div class="top">
        <div class="toptitle">行驶概况</div>
        <ul class="xingshiUl">
          <li class="xsli">
            <div>轨迹完整率</div>
            <div v-if="content.survey1">
              {{ content.survey1 }} <span style="font-size: 12px">%</span>
            </div>
          </li>
          <li class="xsli">
            <div>平均速度</div>
            <div v-if="content.survey2">
              {{ content.survey2 }} <span style="font-size: 12px">km/h</span>
            </div>
          </li>
          <li class="xsli">
            <div>日均运营时长</div>
            <div v-if="content.survey3">
              {{ content.survey3 }} <span style="font-size: 12px">小时</span>
            </div>
          </li>
          <li class="xsli">
            <div>夜间行驶里程占比</div>
            <div v-if="content.survey4">
              {{ content.survey4 }} <span style="font-size: 12px">%</span>
            </div>
          </li>
          <li class="xsli">
            <div>高速公路行驶里程占比</div>
            <div v-if="content.survey5">
              {{ content.survey5 }} <span style="font-size: 12px">%</span>
            </div>
          </li>
          <li class="xsli">
            <div>超速行驶里程占比</div>
            <div v-if="content.survey6">
              {{ content.survey6 }} <span style="font-size: 12px">%</span>
            </div>
          </li>
          <li class="xsli">
            <div>严重超速行驶时长占比</div>
            <div v-if="content.survey7">
              {{ content.survey7 }} <span style="font-size: 12px">%</span>
            </div>
          </li>
          <li class="xsli">
            <div>车辆载重</div>
            <div v-if="content.survey8">
              {{ content.survey8 }}<span style="font-size: 12px">吨</span>
            </div>
          </li>
          <li class="xsli">
            <div>第一长跑路线次数占比</div>
            <div v-if="content.survey9">
              {{ content.survey9 }} <span style="font-size: 12px">%</span>
            </div>
          </li>
        </ul>
      </div>
      <div class="dingwei top">
        <div class="dwtop">
          <ul class="dwt">
            <li>车辆定位</li>
            <li>{{ content.location1 }}</li>
          </ul>
          <div class="dws">上报时间：{{ content.location2 }}</div>
        </div>
        <div class="dwimg">
          <img :src="content.location3" alt="" />
        </div>
        <ul class="dwinfo">
          <li class="dwil">
            <div>{{ content.location4 }}</div>
            <div>车速</div>
          </li>
          <li class="dwil">
            <div>{{ content.location5 }}</div>
            <div>方向</div>
          </li>
          <li class="dwil">
            <div>{{ content.location6 }}</div>
            <div>总里程</div>
          </li>
        </ul>
        <ul class="dwtxt">
          <li><img src="../../assets/iconLocation.png" alt="" /></li>
          <li>{{ content.location7 }}</li>
        </ul>
      </div>
      <div class="err top">
        <div class="etop">车辆风险</div>
        <ul class="errul">
          <li class="errli" :class="{ basic: errKey == 1 }" @click="showErr(1)">
            驾驶风险
          </li>
          <li class="errli" :class="{ basic: errKey == 2 }" @click="showErr(2)">
            运营风险
          </li>
          <li class="errli" :class="{ basic: errKey == 3 }" @click="showErr(3)">
            道路风险
          </li>
        </ul>
        <ul class="errlev">
          <li class="errlevli">
            <div class="eta">A</div>
            <div class="errA"></div>
            <div>优秀</div>
          </li>
          <li class="errlevli">
            <div class="etb">B</div>
            <div class="errB"></div>
            <div>良好</div>
          </li>
          <li class="errlevli">
            <div class="etc">C</div>
            <div class="errC"></div>
            <div>正常</div>
          </li>
          <li class="errlevli">
            <div class="etd">D</div>
            <div class="errD"></div>
            <div>轻微</div>
          </li>
          <li class="errlevli">
            <div class="ete">E</div>
            <div class="errE"></div>
            <div>危险</div>
          </li>
          <li class="errlevli">
            <div class="etf">F</div>
            <div class="errF"></div>
            <div>严重</div>
          </li>
        </ul>
        <ul class="errinfo">
          <li class="errioli" v-for="(item, index) in errlist" :key="index">
            <div>{{ item.name }}</div>
            <div v-if="item.key == 'A'" class="eta">{{ item.key }}</div>
            <div v-if="item.key == 'B'" class="etb">{{ item.key }}</div>
            <div v-if="item.key == 'C'" class="etc">{{ item.key }}</div>
            <div v-if="item.key == 'D'" class="etd">{{ item.key }}</div>
            <div v-if="item.key == 'E'" class="ete">{{ item.key }}</div>
            <div v-if="item.key == 'F'" class="etf">{{ item.key }}</div>
            <div v-if="item.key == 'A'" class="errA errAs errall">
              <img class="era" src="../../assets/insu/1-1.png" alt="" />
            </div>
            <div v-if="item.key == 'B'" class="errB errBs errall">
              <img class="era" src="../../assets/insu/1-2.png" alt="" />
            </div>
            <div v-if="item.key == 'C'" class="errC errCs errall">
              <img class="era" src="../../assets/insu/1-3.png" alt="" />
            </div>
            <div v-if="item.key == 'D'" class="errD errDs errall">
              <img class="era" src="../../assets/insu/1-4.png" alt="" />
            </div>
            <div v-if="item.key == 'E'" class="errE errEs errall">
              <img class="era" src="../../assets/insu/1-5.png" alt="" />
            </div>
            <div v-if="item.key == 'F'" class="errF errFs errall">
              <img class="era" src="../../assets/insu/1-6.png" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <div class="errtips">
        <div class="errtstop">
          <div>风险建议</div>
          <div>
            保持良好的驾驶习惯，转弯时的车速不能超过40公里/时要定期检查轮胎、刹车装置是否正常，保证车辆车况属于正常状态。
          </div>
        </div>
        <div class="errtstop">
          <div>免责说明</div>
          <div>此报告数据仅供于改善驾驶行为，提高商用车营运安全使用。</div>
        </div>
      </div>
    </div>
    <div style="height: 80px"></div>
    <ul class="ul-up">
      <li @click="backPage">上一步</li>
      <li @click="nextPage">下一步</li>
    </ul>
  </div>
</template>

<script>
//
import { riskCroGetTrack } from "@/utils/http.js"; //路径注意
export default {
  data() {
    return {
      errKey: 1,
      errlist: [],
      content: {},
    };
  },
  created() {
    this.getInfo();
  },
  mouted() {},
  methods: {
    backPage() {
      this.$router.go(-1);
    },
    nextPage() {
      this.$router.push("/insurance/err/v1");
    },
    showErr(key) {
      this.errKey = key;
      if (key == 1) this.errlist = this.content.risk1;
      if (key == 2) this.errlist = this.content.risk2;
      if (key == 3) this.errlist = this.content.risk3;
    },

    async getInfo() {
      let str = localStorage.getItem("valuationInfo");
      let valuationInfo = JSON.parse(str);
      let obj = {
        plateNo: valuationInfo.PlateNo,
        vin: valuationInfo.Vin,
      };

      const res = await riskCroGetTrack(obj);
      let content = res.data.content;
      console.log(content);
      content.risk1 = JSON.parse(content.risk1);
      content.risk2 = JSON.parse(content.risk2);
      content.risk3 = JSON.parse(content.risk3);
      this.errlist = content.risk1;
      this.content = content;
      localStorage.setItem("truckInfo", JSON.stringify(content));
    },
  },
};
</script>
<style scoped>
.container {
  background: #f4f4f4;
  padding: 20px 0;
  padding-bottom: 0px;
}
.main {
  box-sizing: border-box;
  padding: 0 10px;
}
.top {
  background: #ffffff;
  box-sizing: border-box;
  padding: 10px;
}
.xingshiUl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #666666;
}
.toptitle {
  font-size: 22px;
  font-weight: bold;
}
.xsli {
  width: 30%;
  font-size: 16px;
  background: #e9f4fd;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 10px;
}
.xsli > div:nth-of-type(1) {
  height: 30px;
  font-size: 12px;
}
.xsli > div:nth-of-type(2) {
  color: #2d6af6;
}
.dingwei {
  margin: 10px 0;
}
.dwtop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dwt {
  display: flex;
  align-items: center;
}
.dwt > li:nth-of-type(1) {
  font-size: 18px;
  font-weight: bold;
}
.dwt > li:nth-of-type(2) {
  font-size: 14px;
  background: #4e6bea;
  padding: 2px 5px;
  border-radius: 10px;
  color: #ffffff;
  margin-left: 10px;
}
.dws {
  font-size: 12px;
  color: #999999;
}
.dwimg {
  width: 100%;
  margin: 10px 0;
}
.dwimg img {
  width: 100%;
}
.dwinfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dwil {
  width: 32%;
  font-size: 10px;
  background: #f4f9fe;
  text-align: center;
  padding: 10px 0;
}
.dwil > div:nth-of-type(1) {
  margin-bottom: 5px;
  font-size: 12px;
}
.dwtxt {
  display: flex;
  font-size: 12px;
  align-items: center;
  margin-top: 20px;
}
.dwtxt > li:nth-of-type(1) {
  margin-right: 4px;
}
.etop {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.errul {
  display: flex;
  font-size: 14px;
  border: 1px solid #999999;
  border-left: none;
  justify-content: space-between;
}
.errul > li {
  padding: 10px 0;
  flex: 1;
  text-align: center;
  border-left: 1px solid #999999;
}
.basic {
  background: #2d6af6;
  color: #ffffff;
}
.errlev {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  text-align: center;
  margin: 10px 0;
}
.errA {
  width: 30px;
  border: 1px solid #82cb83;
}
.eta {
  color: #82cb83;
}
.errB {
  width: 30px;
  border: 1px solid #8dd3da;
}
.etb {
  color: #8dd3da;
}
.errC {
  width: 30px;
  border: 1px solid #617fe3;
}
.etc {
  color: #617fe3;
}
.errD {
  width: 30px;
  border: 1px solid #ecc665;
}
.etd {
  color: #ecc665;
}
.errE {
  width: 30px;
  border: 1px solid #db8747;
}
.ete {
  color: #db8747;
}
.errF {
  width: 30px;
  border: 1px solid #d75548;
}
.etf {
  color: #d75548;
}
.errlevli > div:nth-of-type(2) {
  margin: 10px 0;
}
.errioli {
  display: flex;
  font-size: 14px;
  padding: 10px 0;
  align-items: center;
}
.errioli > div:nth-of-type(1) {
  width: 92px;
  text-align: right;
}
.errioli > div:nth-of-type(2) {
  margin: 0 10px;
}
.errall {
  height: 0px;
  position: relative;
}
.era {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -24px;
  right: 0px;
}
.errAs {
  width: 50px;
}
.errBs {
  width: 70px;
}
.errCs {
  width: 110px;
}
.errDs {
  width: 145px;
}
.errEs {
  width: 180px;
}
.errFs {
  width: 205px;
}
.errtstop {
  background: #f4f9fe;
  font-size: 14px;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 20px;
  margin-top: 10px;
}
.errtstop > div:nth-of-type(1) {
  color: #2d6af6;
  font-size: 20px;
  margin-bottom: 10px;
}
.errtstop > div:nth-of-type(2) {
  line-height: 18px;
}
.ul-up {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  /* border: 1px solid #b7b7b7; */
  background: #ffffff;
}
.ul-up > li {
  line-height: 40px;
}
.ul-up > li:nth-of-type(1) {
  color: #999999;
  flex: 1;
}
.ul-up > li:nth-of-type(2) {
  flex: 2;
  background: #2d6af6;
  color: #ffffff;
  border-radius: 4px;
}
</style>
